import { useEffect, useState } from 'react'
import { collection, onSnapshot } from 'firebase/firestore'
import { db } from '../firebase/firebase'

export const useCollection = col => {
	const [documents, setDocuments] = useState(null)

	useEffect(() => {
		let ref = collection(db, col)
		const unsubscribe = onSnapshot(
			ref,
			snapshot => {
				let result = []
				snapshot.docs.forEach(doc => result.push({ id: doc.id, ...doc.data() }))
				setDocuments(result)
			},
			err => console.log(err)
		)

		return unsubscribe
	}, [col])

	return { documents }
}
