import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { AuthContextProvider } from './context/AuthContext'
import { ProductContextProvider } from './context/ProductContext'

ReactDOM.render(
	<React.StrictMode>
		<ProductContextProvider>
			<AuthContextProvider>
				<App />
			</AuthContextProvider>
		</ProductContextProvider>
	</React.StrictMode>,
	document.getElementById('root')
)
