import React from 'react'
import config from '../../package.json'

export default function Footer() {
	const fullYear = new Date().getFullYear()
	return (
		<footer className='h-12 w-full fixed bottom-0 flex justify-center items-center bg-[#eee] text-sm mt-14'>
			© {fullYear} Havillah Beauty • Version {config.version}
		</footer>
	)
}
