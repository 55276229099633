import React from 'react'
import { useProductContext } from '../hooks/useProductContext'
import { useFirestore } from '../hooks/useFirestore'

export default function DeleteModal({ message, button, collection }) {
	const { dispatch, product: id } = useProductContext()
	const { updateDocument } = useFirestore(collection)

	const handleCancel = () => {
		dispatch({ type: 'CLOSE_MODAL' })
	}

	const handleDelete = () => {
		if (button === 'Delete') {
			// deleteDocument(id)
			updateDocument(id, { isDeleted: true })
			dispatch({ type: 'CLOSE_MODAL' })
		}
		if (button === 'Update') {
			const uid = id[0]
			const group = id[1]

			if (uid && group) {
				updateDocument(uid, { group: group })
			}
			dispatch({ type: 'CLOSE_MODAL' })
		}
	}

	return (
		<div className='flex top-0 left-0 fixed w-screen h-screen justify-center items-center bg-opacity-50 bg-black'>
			<div className='max-w-sm bg-white rounded px-2 py-2'>
				<div className='flex justify-between mb-2'>
					<h4 className='text-lg font-bold'>Confirm</h4>
				</div>
				<div className='text-sm'>
					<p>{message}</p>
				</div>
				<div className='flex justify-end space-x-5 mt-3'>
					<button
						onClick={handleCancel}
						className='bg-gray-200 py-1 px-4 rounded hover:bg-slate-300'>
						Cancel
					</button>
					<button
						onClick={handleDelete}
						className='bg-red-500 text-white py-1 px-4 rounded  hover:bg-red-700'>
						{button}
					</button>
				</div>
			</div>
		</div>
	)
}
