import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import Navbar from './components/Navbar'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Create from './pages/Create'
import Members from './pages/Members'
import Update from './pages/Update'
import { useAuthContext } from './hooks/useAuthContext'
import Footer from './components/Footer'

function App() {
	const { authIsReady, user } = useAuthContext()
	return (
		<div>
			<BrowserRouter>
				{authIsReady && (
					<>
						<Navbar />
						<Routes>
							<Route
								path='/'
								element={user ? <Dashboard /> : <Navigate to='/login' />}
							/>
							<Route
								path='/login'
								element={user ? <Navigate to='/' /> : <Login />}
							/>
							<Route
								path='/create'
								element={user ? <Create /> : <Navigate to='/login' />}
							/>
							<Route
								path='/update/:id'
								element={user ? <Update /> : <Navigate to='/login' />}
							/>
							<Route
								path='/members'
								element={user ? <Members /> : <Navigate to='/login' />}
							/>
						</Routes>
						<Footer />
					</>
				)}
			</BrowserRouter>
		</div>
	)
}

export default App
