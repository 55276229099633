import logo from '../assets/logo.png'
import { Link, NavLink } from 'react-router-dom'
import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext'

export default function Navbar() {
	const { logout } = useLogout()
	const { user } = useAuthContext()

	return (
		<div className='z-50 flex sticky bg-gray-50 top-0 justify-between border-b items-center h-12 px-2 mt-3'>
			<div className='flex items-center'>
				<img
					src={logo}
					alt='havillah logo'
					className=' mr-2 w-10 rounded-full'
				/>
				<Link to='/' className='font-semibold'>
					Havillah cms
				</Link>
			</div>
			<div className='space-x-4'>
				{user && (
					<>
						<NavLink
							className={navData =>
								navData.isActive ? 'font-extrabold ' : 'text-neutral-500'
							}
							to='/create'>
							+ Add
						</NavLink>

						<NavLink
							className={navData =>
								navData.isActive ? 'font-extrabold ' : 'text-neutral-500'
							}
							to='/members'>
							Members
						</NavLink>
					</>
				)}
				{!user && <button>Signup</button>}
				{user && (
					<button
						onClick={logout}
						className='text-neutral-500 border-2 border-pink-500 px-2 rounded-md hover:border-gray-600'>
						Logout
					</button>
				)}
			</div>
		</div>
	)
}
