import { useEffect, useState } from 'react'
import Switch from 'react-switch'
import Select from 'react-select'
import { useProductContext } from '../hooks/useProductContext'
import { useFirestore } from '../hooks/useFirestore'

const categories = [
	{ value: 'elite', label: 'Elite Sales' },
	{ value: 'salesRep', label: 'Sales Rep' },
	{ value: 'makeup', label: 'Makeuppreneur' },
]

export default function MemberCard({ user }) {
	const [group, setGroup] = useState()
	const [color, setColor] = useState('')
	const [checked, setChecked] = useState(user.active)
	const [selectedGroup, setSelectedGroup] = useState(null)
	const { dispatch } = useProductContext()
	const { updateDocument } = useFirestore('userDetails')

	useEffect(() => {
		if (user.group === 'elite') {
			setGroup('Elite sales')
			setColor('text-red-500')
		} else if (user.group === 'makeup') {
			setGroup('Makeuppreneur')
			setColor('text-blue-500')
		} else if (user.group === 'salesRep') {
			setGroup('Sales Rep')
			setColor('text-amber-500')
		} else {
			setGroup('Not assigned')
		}
	}, [user])

	const handleChange = id => {
		setChecked(!checked)
		updateDocument(id, { active: !checked })
	}

	const handleSubmit = () => {
		if (selectedGroup.value) {
			dispatch({ type: 'OPEN_MODAL', payload: [user.id, selectedGroup.value] })
		}
	}
	return (
		<div className='border max-w-2xl bg-white shadow-sm rounded-sm'>
			<div className='flex relative p-3'>
				<div className='absolute right-2 top-2'>
					<Switch onChange={() => handleChange(user.id)} checked={checked} />
				</div>
				<div className='flex overflow-hidden h-32 w-32 border-2 mr-5 rounded-full bg-slate-400 items-center justify-center'>
					<img src={user.profilePic} alt='avatar' className='h-32' />
				</div>
				<div className='pt-5'>
					<p>{user.fullName}</p>
					<p className={color}>{group}</p>
					<p className='text-sm text-neutral-500'>Username: {user.username}</p>
					<p className='text-sm text-neutral-500'>cellphone: {user.whatsapp}</p>
					<p className='text-sm text-neutral-500'>{user.email}</p>
					<div className='border-b my-2 w-full' />
					<p className='text-xs text-neutral-500 mb-1'>
						Select below to change member's sales group
					</p>
					<div className='flex items-center'>
						<Select
							options={categories}
							onChange={option => setSelectedGroup(option)}
						/>
						<button
							disabled={!selectedGroup}
							onClick={handleSubmit}
							style={{
								background: !selectedGroup && '#eee',
								border: !selectedGroup && '#333',
							}}
							className='ml-5 border py-2 px-2 rounded-md border-pink-500 hover:bg-pink-500 hover:text-white'>
							Update
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
