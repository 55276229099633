import { useEffect, useState } from 'react'
import Select from 'react-select'
import Switch from 'react-switch'
import { useFirestore } from '../hooks/useFirestore'
import { storage } from '../firebase/firebase'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { useNavigate } from 'react-router-dom'

const categories = [
	{ value: 'elite', label: 'Elite Sales' },
	{ value: 'salesRep', label: 'Sales Rep' },
	{ value: 'makeup', label: 'Makeuppreneur' },
]

export default function Create() {
	const [name, setName] = useState('')
	const [size, setSize] = useState('')
	const [amount, setAmount] = useState('')
	const [originalAmount, setOriginalAmount] = useState('')
	const [minQuantity, setMinQuantity] = useState('')
	const [discountedAmount, setDiscountedAmount] = useState('#eeeeee')
	const [backgroundColor, setBackgroundColor] = useState('')
	const [isPromo, setIsPromo] = useState(false)
	const [thumbnailError, setThumbnailError] = useState(null)
	const [selectedImage, setSelectedImage] = useState(null)
	const [previewImage, setPreviewImage] = useState(null)
	const [selectedGroup, setSelectedGroup] = useState([])
	const [elite, setElite] = useState(false)
	const [makeup, setMakeup] = useState(false)
	const [salesRep, setSalesRep] = useState(false)
	const [formError, setFormError] = useState(null)
	const navigate = useNavigate()

	const { addDocument } = useFirestore('products')

	useEffect(() => {
		selectedGroup.forEach(user => {
			if (user.value === 'elite') {
				setElite(true)
			}
			if (user.value === 'salesRep') {
				setSalesRep(true)
			}
			if (user.value === 'makeup') setMakeup(true)
		})
	}, [selectedGroup, salesRep, elite, makeup])

	const handleSubmit = e => {
		e.preventDefault()
		setFormError(null)

		const newProduct = {
			color: backgroundColor,
			descQuantity: 10,
			discPrice: discountedAmount,
			image: selectedImage,
			minQuantity: minQuantity,
			name: name,
			price: amount,
			promo: isPromo,
			size: size,
			unitPrice: originalAmount,
			elite: elite,
			salesRep: salesRep,
			makeup: makeup,
		}

		if (selectedGroup.length < 1) {
			setFormError('Please assign the project to at least 1 user')
			return
		}

		if (!selectedImage) {
			setThumbnailError('Please select an image')
			return
		}

		addDocument(newProduct).then(() => navigate('/'))
	}

	const handleFileChange = async e => {
		setSelectedImage(null)
		setPreviewImage(null)
		let selected = e.target.files[0]

		if (!selected) {
			setThumbnailError('Please select an image')
			return
		}
		if (selected.size > 200000) {
			setThumbnailError('Image size must be less than 200kb')
			return
		}

		const imagePathRef = ref(storage, `products/${selected.name}`)
		await uploadBytesResumable(imagePathRef, selected)
		const url = await getDownloadURL(imagePathRef)
		setSelectedImage(url)

		setThumbnailError(null)
		const reader = new FileReader()
		reader.addEventListener('load', () => {
			setPreviewImage(reader.result)
		})
		reader.readAsDataURL(selected)
	}

	const handleChange = () => {
		setIsPromo(!isPromo)
	}

	return (
		<div className='flex flex-col mx-auto mt-5  p-5 items-center'>
			<div className='flex mb-5 bg-white border px-2 py-8'>
				<div className='mx-5 h-80 w-72'>
					<div
						style={{ background: backgroundColor ? backgroundColor : '#eee' }}>
						<img src={previewImage} alt='' className='h-80 w-72' />
					</div>
					<div className='flex my-3 items-center'>
						<input
							className='mr-2 h-8'
							type='color'
							value={backgroundColor}
							onChange={e => setBackgroundColor(e.target.value)}
						/>
						<span>Background colour</span>
					</div>
					{thumbnailError && (
						<p className='text-xs text-red-500 my-2'>{thumbnailError}</p>
					)}
					<div className='flex flex-col my-3'>
						<span>Upload image:</span>
						<input
							className='form'
							type='file'
							accept='image/png'
							onChange={handleFileChange}
						/>
					</div>
				</div>
				<form onSubmit={handleSubmit} className='flex flex-col mr-5'>
					<label className='flex flex-col'>
						<span>name:</span>
						<input
							className='form'
							type='text'
							required
							value={name}
							onChange={e => setName(e.target.value)}
						/>
					</label>
					<label className='flex flex-col'>
						<span>product size:</span>
						<input
							className='form'
							type='text'
							required
							value={size}
							onChange={e => setSize(e.target.value)}
						/>
					</label>
					<label className='flex flex-col'>
						<span>Promo:</span>
						<span className='text-xs text-neutral-500'>
							Is this a temporary promo?.
						</span>
						<Switch onChange={handleChange} checked={isPromo} />
					</label>
					<label className='flex flex-col'>
						<span>stock price:</span>
						<span className='text-xs text-neutral-500'>
							Amount that shows on the app.
						</span>
						<input
							className='form'
							type='number'
							required
							value={amount}
							onChange={e => setAmount(parseInt(e.target.value))}
						/>
					</label>
					<label className='flex flex-col'>
						<span>minimum quantity:</span>
						<span className='text-xs text-neutral-500'>
							Minimum you must buy to quality for a discount.
						</span>
						<input
							className='form'
							type='number'
							required
							value={minQuantity}
							onChange={e => setMinQuantity(parseInt(e.target.value))}
						/>
					</label>
					<label className='flex flex-col'>
						<span>original price:</span>
						<span className='text-xs text-neutral-500'>
							Amount when buying less than 5 items.
						</span>
						<input
							className='form'
							type='number'
							required
							value={originalAmount}
							onChange={e => setOriginalAmount(parseInt(e.target.value))}
						/>
					</label>
					<label className='flex flex-col'>
						<span>discounted price:</span>
						<span className='text-xs text-neutral-500'>
							Amount when buying more than 10 items.
						</span>
						<input
							className='form'
							type='number'
							required
							value={discountedAmount}
							onChange={e => setDiscountedAmount(parseInt(e.target.value))}
						/>
					</label>
					<label className='flex flex-col'>
						<span>Group:</span>
						<span className='text-xs text-neutral-500'>
							Select which group/s can see this product.
						</span>
						<Select
							options={categories}
							onChange={option => setSelectedGroup(option)}
							isMulti
						/>
					</label>
					{formError && (
						<p className='text-xs text-red-500 my-2'>{formError}</p>
					)}
					<button className='mt-5 border py-2 px-5 rounded-md border-pink-500 hover:bg-pink-500 hover:text-white'>
						Add product
					</button>
				</form>
			</div>
		</div>
	)
}
