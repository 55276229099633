import { createContext, useReducer } from 'react'

export const ProductContext = createContext()

const initialSate = {
	isLoading: true,
	product: [],
	openModal: false,
}

const productReducer = (state, action) => {
	switch (action.type) {
		case 'LOAD_PRODUCT':
			return { isLoading: false, product: action.payload }
		case 'OPEN_MODAL':
			return { ...state, openModal: true, product: action.payload }
		case 'CLOSE_MODAL':
			return { ...state, openModal: false }
		default:
			return state
	}
}

export const ProductContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(productReducer, initialSate)
	return (
		<ProductContext.Provider value={{ ...state, dispatch }}>
			{children}
		</ProductContext.Provider>
	)
}
