import { useState } from 'react'
import { auth } from '../firebase/firebase'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { useAuthContext } from './useAuthContext'

export const useLogin = () => {
	const { dispatch } = useAuthContext()
	const [error, setError] = useState(null)

	const login = async (email, password) => {
		setError(null)
		try {
			const result = await signInWithEmailAndPassword(auth, email, password)
			dispatch({
				type: 'LOGIN',
				payload: result.user,
			})
		} catch (error) {
			setError(error.message)
		}
	}

	return { login, error }
}
