import { useState } from 'react'
import Product from '../components/Product'
import { useCollection } from '../hooks/useCollection'
import DeleteModal from '../components/DeleteModal'
import { useProductContext } from '../hooks/useProductContext'

export default function Dashboard() {
	const [isOpen, setIsOpen] = useState(false)
	const { documents } = useCollection('products')
	const { openModal } = useProductContext()

	return (
		<div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 max-w-5xl mx-auto mt-5 mb-20'>
			{documents &&
				documents.map(doc => (
					<Product
						key={doc.id}
						item={doc}
						setIsOpen={setIsOpen}
						isOpen={isOpen}
					/>
				))}
			{openModal && (
				<DeleteModal
					message='Are you sure you want to remove this product from the app?'
					button='Delete'
					collection='products'
				/>
			)}
		</div>
	)
}
