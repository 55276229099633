import { useState } from 'react'
import DeleteModal from '../components/DeleteModal'
import MemberCard from '../components/MemberCard'
import { useCollection } from '../hooks/useCollection'
import { useProductContext } from '../hooks/useProductContext'

export default function Members() {
	const { documents } = useCollection('userDetails')
	const { openModal } = useProductContext()

	const [searchTerm, setSearchTerm] = useState('')
	const [searchResult, setSearchResult] = useState([])

	const filteredUsers = documents?.filter(user => user.done === true)

	const changeHandler = term => {
		setSearchTerm(term)
		if (term !== '') {
			const foundUser = filteredUsers.filter(user =>
				Object.values(user)
					.join('')
					.toLocaleLowerCase()
					.includes(term.toLocaleLowerCase())
			)
			setSearchResult(foundUser)
		}
	}

	return (
		<div className='flex max-w-5xl flex-col mx-auto mt-5  px-5'>
			<p>All member statuses</p>
			<input
				placeholder='Search by email, name, cellphone etc.'
				className='form mt-3'
				value={searchTerm}
				onChange={e => changeHandler(e.target.value)}
			/>
			<hr className='my-2' />
			<div className='grid grid-cols-1 md:grid-cols-2 gap-5 mx-auto'>
				{searchTerm.length > 1 ? (
					searchResult?.map(user => <MemberCard key={user.id} user={user} />)
				) : (
					<>
						{filteredUsers?.map(user => (
							<MemberCard key={user.id} user={user} />
						))}
					</>
				)}
			</div>
			{openModal && (
				<DeleteModal
					message='Are you sure you want to change the member from their current group?'
					button='Update'
					collection='userDetails'
				/>
			)}
		</div>
	)
}
