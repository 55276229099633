import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Switch from 'react-switch'
import { useFirestore } from '../hooks/useFirestore'
import { useProductContext } from '../hooks/useProductContext'
import { storage } from '../firebase/firebase'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'

export default function Update() {
	const { product } = useProductContext()
	const { updateDocument } = useFirestore('products')
	const navigate = useNavigate()

	const [name, setName] = useState(product.name)
	const [size, setSize] = useState(product.size)
	const [amount, setAmount] = useState(product.price)
	const [originalAmount, setOriginalAmount] = useState(product.unitPrice)
	const [minQuantity, setMinQuantity] = useState(product.minQuantity)
	const [discountedAmount, setDiscountedAmount] = useState(product.discPrice)
	const [backgroundColor, setBackgroundColor] = useState(product.color)
	const [isPromo, setIsPromo] = useState(product.promo ? product.promo : false)
	const [thumbnailError, setThumbnailError] = useState(null)
	const [selectedImage, setSelectedImage] = useState(product.image)
	const [previewImage, setPreviewImage] = useState(product.image)
	const [showElite, setShowElite] = useState('')
	const [showMakeup, setShowMakeup] = useState('')
	const [showSalesRap, setShowSalesRap] = useState('')
	const [elite, setElite] = useState(product.elite)
	const [makeup, setMakeup] = useState(product.makeup)
	const [salesRep, setSalesRep] = useState(product.salesRep)

	useEffect(() => {
		if (product.elite === true) {
			setShowElite('Elite Group')
		}
		if (product.makeup === true) {
			setShowMakeup('Makeuppreneur')
		}
		if (product.salesRep === true) {
			setShowSalesRap('Sales Rep')
		}
	}, [product])

	const handleSubmit = e => {
		e.preventDefault()

		const updatedProduct = {
			color: backgroundColor,
			descQuantity: 10,
			discPrice: discountedAmount,
			image: selectedImage,
			minQuantity: minQuantity,
			name: name,
			price: amount,
			promo: isPromo,
			size: size,
			unitPrice: originalAmount,
			elite: elite,
			salesRep: salesRep,
			makeup: makeup,
		}

		if (!selectedImage) {
			setThumbnailError('Please select an image')
			return
		}

		updateDocument(product.id, updatedProduct).then(() => navigate('/'))
	}

	const handleFileChange = async e => {
		setSelectedImage(null)
		setPreviewImage(null)
		let selected = e.target.files[0]

		if (!selected) {
			setThumbnailError('Please select an image')
			return
		}
		if (selected.size > 200000) {
			setThumbnailError('Image size must be less than 200kb')
			return
		}

		const imagePathRef = ref(storage, `products/${selected.name}`)
		await uploadBytesResumable(imagePathRef, selected)
		await getDownloadURL(imagePathRef).then(url => setSelectedImage(url))

		setThumbnailError(null)
		const reader = new FileReader()
		reader.addEventListener('load', () => {
			setPreviewImage(reader.result)
		})
		reader.readAsDataURL(selected)
	}

	const handleEliteChange = () => {
		setElite(!elite)
		updateDocument(product.id, { elite: !elite }).catch(err =>
			console.log('err', err)
		)
	}
	const handleMakeupChange = () => {
		setMakeup(!makeup)
		updateDocument(product.id, { makeup: !makeup }).catch(err =>
			console.log('err', err)
		)
	}
	const handleSalesChange = () => {
		setSalesRep(!salesRep)
		updateDocument(product.id, { salesRep: !salesRep }).catch(err =>
			console.log('err', err)
		)
	}

	const handleChange = () => {
		setIsPromo(!isPromo)
	}

	useEffect(() => {
		if (!product.id) {
			navigate('/')
		}
	}, [navigate, product])

	return (
		<div className='flex flex-col mx-auto mt-5 mb-10 p-5 items-center'>
			<div className='mb-2 flex-col justify-center flex items-center'>
				<p className=''>Update product detail for: {product.name}</p>

				<p className='text-sm p-1'>
					Product currently available on:{' '}
					<span className='text-green-500'>{showElite}</span>{' '}
					<span className='text-red-500'>
						{showMakeup && '• ' + showMakeup}
					</span>{' '}
					<span className='text-orange-500'>
						{showSalesRap && '• ' + showSalesRap}
					</span>
				</p>
				<span className='text-xs text-neutral-500'>
					NB: Only update what you need changed on the app, the rest will remain
					unchanged.
				</span>
			</div>
			<div className='flex mb-10 bg-white border px-2 pt-8 pb-20'>
				<div className='mx-5 h-80 w-72'>
					<div
						style={{
							background: backgroundColor ? backgroundColor : '#eeeeee',
						}}>
						<img src={previewImage} alt='' className='h-80 w-72' />
					</div>
					<div className='flex my-3 items-center'>
						<input
							className='mr-2 h-8'
							type='color'
							value={backgroundColor}
							onChange={e => setBackgroundColor(e.target.value)}
						/>
						<span>Background colour</span>
					</div>
					{thumbnailError && (
						<p className='text-xs text-red-500 my-2'>{thumbnailError}</p>
					)}
					<div className='flex flex-col my-3'>
						<span>Upload image:</span>
						<input
							className='form'
							type='file'
							accept='image/png'
							onChange={handleFileChange}
						/>
					</div>
					<div className='flex flex-col space-y-5'>
						<p className='text-md font-semibold text-center bg-purple-400 px-1'>
							Product shows in below groups:
						</p>
						<div className='flex items-center space-x-2 w-full'>
							<Switch onChange={handleEliteChange} checked={elite} />
							<p>Elite Group</p>
						</div>
						<div className='flex items-center space-x-2 w-full'>
							<Switch onChange={handleMakeupChange} checked={makeup} />
							<p>Makeuppreneur</p>
						</div>
						<div className='flex items-center space-x-2 w-full'>
							<Switch onChange={handleSalesChange} checked={salesRep} />
							<p>Sales Rep</p>
						</div>
					</div>
				</div>
				<form onSubmit={handleSubmit} className='flex flex-col mr-5'>
					<label className='flex flex-col'>
						<span>name:</span>
						<input
							className='form'
							type='text'
							value={name}
							onChange={e => setName(e.target.value)}
						/>
					</label>
					<label className='flex flex-col'>
						<span>product size:</span>
						<input
							className='form'
							type='text'
							value={size}
							onChange={e => setSize(e.target.value)}
						/>
					</label>
					<label className='flex flex-col'>
						<span>Promo:</span>
						<span className='text-xs text-neutral-500'>
							Is this a temporary promo?.
						</span>
						<Switch onChange={handleChange} checked={isPromo} />
					</label>
					<label className='flex flex-col'>
						<span>stock amount:</span>
						<span className='text-xs text-neutral-500'>
							Amount that shows on the app.
						</span>
						<input
							className='form'
							type='number'
							value={amount}
							onChange={e => setAmount(parseInt(e.target.value))}
						/>
					</label>
					<label className='flex flex-col'>
						<span>minimum quantity:</span>
						<span className='text-xs text-neutral-500'>
							Minimum you must buy to quality for a discount.
						</span>
						<input
							className='form'
							type='number'
							value={minQuantity}
							onChange={e => setMinQuantity(parseInt(e.target.value))}
						/>
					</label>
					<label className='flex flex-col'>
						<span>original amount:</span>
						<span className='text-xs text-neutral-500'>
							Amount when buying less than 5 items.
						</span>
						<input
							className='form'
							type='number'
							value={originalAmount}
							onChange={e => setOriginalAmount(parseInt(e.target.value))}
						/>
					</label>
					<label className='flex flex-col'>
						<span>discounted amount:</span>
						<span className='text-xs text-neutral-500'>
							Amount when buying more than 10 items.
						</span>
						<input
							className='form'
							type='number'
							value={discountedAmount}
							onChange={e => setDiscountedAmount(parseInt(e.target.value))}
						/>
					</label>
					<button className='mt-5 border py-2 px-5 rounded-md border-pink-500 hover:bg-pink-500 hover:text-white'>
						Update product
					</button>
				</form>
			</div>
		</div>
	)
}
