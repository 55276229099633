import { Link } from 'react-router-dom'
import { useProductContext } from '../hooks/useProductContext'
import { useFirestore } from '../hooks/useFirestore'

export default function Product({ item }) {
	const { dispatch } = useProductContext()
	const { updateDocument } = useFirestore('products')

	const handleDelete = () => {
		dispatch({ type: 'OPEN_MODAL', payload: item.id })
	}
	const handleRestoration = () => {
		updateDocument(item.id, { isDeleted: false })
	}
	const handleClick = () => {
		dispatch({ type: 'LOAD_PRODUCT', payload: item })
	}

	return (
		<div className='border max-w-md p-3 bg-white shadow-sm rounded-sm'>
			<img
				src={item.image}
				alt={item.name}
				style={{ background: item.color }}
				className='w-full bg-gray-100 h-56'
			/>
			{item.isDeleted ? (
				<div className='w-full justify-center flex flex-col space-y-2 mt-3'>
					<p>This product has been removed</p>
					<button
						onClick={handleRestoration}
						className='border text-white py-1 border-green-700 px-5 rounded bg-green-500 hover:bg-green-600'>
						Restore it
					</button>
				</div>
			) : (
				<>
					<div className='mb-2'>
						<p>{item.name}</p>
						<p className='text-sm'>amount: R {item.price}</p>
						<p className='text-sm'>size: {item.size}</p>
					</div>
					<div className='flex justify-between'>
						<button
							onClick={handleDelete}
							className='border text-white py-1 border-red-700 px-5 rounded bg-red-500 hover:bg-red-600'>
							Delete
						</button>
						<Link
							to={`/update/${item.id}`}
							onClick={handleClick}
							className='border  py-1 border-pink-500 px-5 rounded hover:bg-neutral-300 hover:border-neutral-400 '>
							Update
						</Link>
					</div>
				</>
			)}
		</div>
	)
}
