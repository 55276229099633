import { createContext, useReducer, useEffect } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../firebase/firebase'
export const AuthContext = createContext()

const initialState = {
	user: null,
	authIsReady: false,
}

const authReducer = (state, action) => {
	switch (action.type) {
		case 'LOGIN':
			return { ...state, user: action.payload }
		case 'AUTH_IS_READY':
			return { user: action.payload, authIsReady: true }
		case 'LOGOUT':
			return { ...state, user: null }
		default:
			return state
	}
}

export const AuthContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(authReducer, initialState)

	useEffect(
		() =>
			onAuthStateChanged(auth, user => {
				dispatch({ type: 'AUTH_IS_READY', payload: user })
			}),
		[]
	)

	// console.log('state in AuthCOntext', state)

	return (
		<AuthContext.Provider value={{ ...state, dispatch }}>
			{children}
		</AuthContext.Provider>
	)
}
